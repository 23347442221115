<template>
<div class="single-data">
  <div class="single-data-header">
    <el-date-picker
        @change="selectTimeChange"
        style="width: 254px;"
        v-model="dateScopeValue"
        type="daterange"
        range-separator="~"
        start-placeholder="开始日期"
        :clearable="false"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
        end-placeholder="结束日期">
    </el-date-picker>
    <span>按开播时间筛选</span>
  </div>
  <el-table :data="tiktokLiveData" style="width: 100%; margin-top: 10px;flex: 1" height="1%" size="medium" class="attention-table"
            :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#333333', fontSize:'16px'}"
            :cell-style="{fontSize: '16px',color: '#333333',height: '160px',}">
    <el-table-column align="left" label="直播封面/名称" width="360">
      <template slot-scope="scope">
        <div class="line-live-info">
          <div class="live-info-img">
            <img :src="scope.row.room_cover_image" alt="">
          </div>
          <div class="live-info-content">
            <span class="text-overflow">{{scope.row.room_name}}</span>
            <span>直播时间:{{scope.row.open_time}}</span>
            <span>直播时长:{{scope.row.duration}}</span>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="watch_pcu" label="观看人数"></el-table-column>
    <el-table-column align="center" prop="watch_ucnt" label="在线人数峰值"></el-table-column>
    <el-table-column align="center" prop="comment_ucnt" label="评论人数"></el-table-column>
    <el-table-column align="center" prop="consume_ucnt" label="付费人数"></el-table-column>
    <el-table-column align="center" prop="score" label="收获音浪"></el-table-column>
    <el-table-column align="center" prop="income" label="收益"></el-table-column>
    <el-table-column align="center" label="更多">
      <template slot-scope="scope">
        <span style="color: #584EEE;cursor: pointer;" @click="lookAudience(scope.row)">查看</span>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog
      @open="openLiveDialog"
      title="查看更多数据"
      custom-class="like-look-dialog"
      :visible.sync="dialogVisible"
      width="43%">
    <div class="look-dialog-name">观众分布</div>
    <div id="LiveAudienceDistribution" style="width: 100%;height: 380px;position: relative"></div>
  </el-dialog>
  <el-pagination
  class="circle-page" style="padding-top: 20px;"
  :current-page="liveDataPages.currentPageNum"
  :page-size="liveDataPages.eachPageNum"
  layout="prev, pager, next, jumper"
  :total="liveDataPages.total"
  @current-change="liveDataCurrentChange">
  </el-pagination>
</div>
</template>

<script>
export default {
  name: "LiveSingleData",
  data(){
    return {
      dateScopeValue:[],
      pickerOptions:{
        // disabledDate(time){
        //   console.log(time.getTime())
        //   return time.getTime() > Date.now();
        // }
      },
      tiktokLiveData:[],
      liveDataPages:{
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0
      },
      dialogVisible:false,
      AudienceDistributionNoPrompt:null,
      accountID:null,
      platform:null,
      generalSourceList:[],
    }
  },
  mounted() {
    this.AudienceDistributionNoPrompt = document.createElement("div");
  },
  methods:{
    deleDateScopeValue(){
      this.dateScopeValue = [];
      this.tiktokLiveData = [];
      this.accountID = '';
      this.platform = '';
      this.liveDataPages = {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0
      }
      this.pickerOptions = {
        disabledDate(){

        }
      }

    },
    getTiktokLive(accountID,platform){
      let param = {
        page: this.liveDataPages.currentPageNum,
        paging: 1,
        pageSize: this.liveDataPages.eachPageNum
      }
      if(accountID && platform){
        this.accountID = accountID;
        this.platform = platform;
        param.id = accountID;
        param.platform_type = platform
      } else {
        param.id = this.accountID;
        param.platform_type = this.platform;
      }
      // 传的时间戳开始为 00:00:00  结束为23:59:59
      if(this.dateScopeValue && this.dateScopeValue.length){
        let start = JSON.stringify(this.dateScopeValue[0]) +" "+ "00" +":"+ "00" +":" + "00";
        let end = JSON.stringify(this.dateScopeValue[1]) +" "+ "23" +":"+ "59" +":" + "59";
        param.start_time = new Date(start).getTime() / 1000;
        param.end_time = new Date(end).getTime() / 1000;
      }
        this.$httpStudent.axiosGetBy(this.$api.tiktok_live, param, res=>{
          if(res.code === 200){
            if(res.data.earliestTime && res.data.latestTime){
              // 限制选择时间和dom显示格式
              let star =  this.formatDate(new Date(res.data.earliestTime * 1000), true)
              let starText = this.formatDate(new Date(res.data.earliestTime * 1000), false);
              let end = this.formatDate(new Date(res.data.latestTime * 1000), true);
              let endText = this.formatDate(new Date(res.data.latestTime * 1000), false);
              let starTimestamp = new Date(star).getTime();
              let endTimestamp = new Date(end).getTime();
              this.pickerOptions = {
                disabledDate(time){
                  return time.getTime() < starTimestamp || time.getTime() > endTimestamp
                },
              }
              this.dateScopeValue = [starText,endText]
            } else {
              this.$nextTick(()=>{
                this.pickerOptions = {
                  disabledDate(){

                  }
                }
              })
            }
            this.tiktokLiveData = res.data.data;
            this.liveDataPages.total = res.data.total;
          } else {
            this.$message.warning(res.message)
          }
        })
    },
    selectTimeChange(val){
      this.getTiktokLive();
    },
    formatDate(now,isZero) {
      let year=now.getFullYear();
      let month=now.getMonth()+1 > 10 ? now.getMonth()+1 : '0' + (now.getMonth()+1);
      let date=now.getDate() > 10 ? now.getDate() : '0' + now.getDate();
      // let hour=now.getHours();
      // let minute=now.getMinutes();
      // let second=now.getSeconds();
      if(isZero){
        return year+"-"+month+"-"+date+" "+ "00" +":"+ "00" +":" + "00"
      } else {
        return year+"-"+month+"-"+date
      }
      },
// 点击查看
    lookAudience(row){
      if(row.other && row.other.general_source_list && row.other.general_source_list.length){
        row.other.general_source_list.map(item=>{
          item.count = Number(item.count)
        })
        this.generalSourceList = row.other.general_source_list;
      } else {
        this.generalSourceList = [];
      }
      this.dialogVisible = true;
      // let param = {
      //   id:this.accountID,
      //   room_id:id
      // }
      // this.$httpStudent.axiosGetBy(this.$api.tiktok_live_more, param, res=>{
      //   if(res.code === 200){
      //     if(res.data.general_source_list && res.data.general_source_list.length){
      //       res.data.general_source_list.map(item=>{
      //         item.count = Number(item.count)
      //       })
      //       this.generalSourceList = res.data.general_source_list;
      //       this.dialogVisible = true;
      //     } else {
      //       this.generalSourceList = [];
      //     }
      //   } else {
      //     this.$message.warning(res.message)
      //   }
      // })
    },
    openLiveDialog(){
      let dataArray = [];
      let totalNum = 0;
      // 总数 计算百分比
      this.generalSourceList.forEach(item=>{
        totalNum += item.count
      });

      this.generalSourceList.forEach(item=>{
        let obj = {
          name:item.source,
          value:Number(this.getPercent(item.count, totalNum, false))
        }
        dataArray.push(obj)
      })
      this.$nextTick(()=>{
        let LiveAudienceDistribution = this.$echarts.init(document.getElementById('LiveAudienceDistribution'));
        LiveAudienceDistribution.setOption({
          tooltip : { //提示框组件
            trigger: 'item', //触发类型(饼状图片就是用这个)
            formatter:function (params){
              return '观众分布' + '<br/>' + params.name + '：' + params.value+'%'
            }
          },
          color:['#F5AF23','#9ECB59','#5F55F1','#F5697B','#C3C3F4'],
          legend: {
            padding:[0,80,40,0],
            orient : 'horizontal',  //布局  纵向布局 图例标记居文字的左边 vertical则反之
            width:40,      //图行例组件的宽度,默认自适应
            x : 'right',   //图例显示在右边
            y: 'bottom',   //图例在垂直方向上面显示居中
            itemWidth:20,  //图例标记的图形宽度
            itemHeight:10, //图例标记的图形高度
            // data:audienceArray,
            textStyle:{
              color:'#000',
              fontSize:14,
              lineHeight:22
            }
          },
          series: [{
            // name: '观众分布',
            type: 'pie',
            data: dataArray,
            center:['40%','58%'],
            radius : ['60%', '70%'],
            itemStyle:{
              normal:{
                label : {  //饼图图形上的文本标签
                  show : true,  //平常不显示
                  color:'#000',
                  fontSize:18,
                  formatter: function (params) {
                    return params.value + '%';
                  }
                },
                labelLine : {     //标签的视觉引导线样式
                  show : true  //平常不显示
                },
              },
              emphasis:{
                label:{
                  show : true,
                  position : 'center',
                  textStyle : {
                    fontSize : '10',
                    fontWeight : 'bold'
                  }
                }
              }
            }
          }]
        })
        if (dataArray.length === 0) {
          this.AudienceDistributionNoPrompt.innerHTML = "暂无数据";
          this.AudienceDistributionNoPrompt.style.color = "#666";
          this.AudienceDistributionNoPrompt.style.position = "absolute";
          this.AudienceDistributionNoPrompt.style.top = "100px";
          this.AudienceDistributionNoPrompt.style.left = "50%";
          document.querySelector("#LiveAudienceDistribution").appendChild(this.AudienceDistributionNoPrompt)
        }
      })
    },
    //计算百分比
    getPercent(curNum, totalNum, isHasPercentStr){
      curNum = parseFloat(curNum);
      totalNum = parseFloat(totalNum);
      if(isNaN(curNum) || isNaN(totalNum)){
        return '-';
      }
      return isHasPercentStr ?
          totalNum <= 0 ? '0%' : (Math.round(curNum / totalNum * 10000) / 100.00 + '%') :
          totalNum <= 0 ? 0 : (Math.round(curNum / totalNum * 10000) / 100.00);
    },
    liveDataCurrentChange(val){
      this.liveDataPages.currentPageNum = val;
      this.getTiktokLive()
    }
  }
}
</script>

<style scoped lang="scss">
.single-data{
  height: calc(100vh - 260px);
  //height: 100%;
  display: flex;
  flex-direction: column;
  padding: 14px 20px 20px;
  .single-data-header{
    display: flex;
    align-items: center;
    span{
      font-size: 14px;
      color: #333;
      margin-left: 12px;
    }
  }
}
::v-deep .like-look-dialog{
  .el-dialog__header{
    margin:0 20px;
    padding: 20px 0;
    color: #333;
    font-size: 18px;
    border-bottom: #DDDDDD 1px solid;
  }
  .el-dialog__body{
    padding: 20px 20px 60px;
    .look-dialog-name{
      font-size: 18px;
      color: #333;
      text-align: center;
    }
  }
}
.line-live-info{
  width: 100%;
  display: flex;
  .live-info-img{
    width: 105px;
    height: 130px;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  .live-info-content{
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1;
    flex: 1;
    width: 1%;
    span{
      color: #999999;
      font-size: 12px;
      &:nth-child(1){
        color: #333;
        font-size: 16px;
      }
      &:nth-child(2){
        margin: 18px 0;
      }
    }
  }
}
</style>