<template>
  <div class="live-container">
    <div class="live-top">
      <div class="live-top-left">
        <el-select ref="select" class="platform-select" :class="{'platform-select-img' : platformCover !== null || ''}" v-model="platformValue" placeholder="请选择"
                   @change="platformSelection"
                   :popper-append-to-body="false">
          <el-option
              v-for="item in platformList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            <img :src="item.imgSrc ? item.imgSrc : blank_avatar"
                 alt="" width="26"
                 height="26">
            <span class="text-overflow">{{ item.label }}</span>
          </el-option>
        </el-select>
        <img class="platform-cover" v-if="platformCover" :src="platformCover" alt="">
      </div>
      <div class="live-top-tight">
        <el-select ref="select" class="account-select" :class="{'platform-select-img' : accountCover !== null || ''}" v-model="accountValue" placeholder="请选择"
                   @change="accountListSelection"
                   :popper-append-to-body="false">
          <el-option
              v-for="item in accountList"
              :key="item.id"
              :label="item.nickname"
              :value="item.id">
            <img :src="item.avatar ? item.avatar : blank_avatar"
                 alt="" width="26"
                 height="26">
            <span class="text-overflow">{{ item.nickname }}</span>
          </el-option>
        </el-select>
        <img class="platform-cover" v-if="accountCover" :src="accountCover" alt="">
      </div>
    </div>
    <div class="live-bottom">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="数据概况" name="first" lazy>
          <LiveDataSituation ref="LiveDataSituation" />
        </el-tab-pane>
        <el-tab-pane label="单场数据" name="second" lazy>
          <LiveSingleData ref="LiveSingleData" />
        </el-tab-pane>
      </el-tabs>
      <div class="update-content">
        <span @click="updateBtn" class="update-title"><i class="iconfont">&#xe64d;</i>更新数据</span>{{update_time}}
      </div>
      <TrillAccountModule ref="TrillAccountModule" />
    </div>
    <div class="qr-code-box" v-if="qrCodeShow">
      <i @click="closeQrCode" class="iconfont">&#xe623;</i>
      <div class="qr-code">
        <img v-if="qrCodeSrc" :src="qrCodeSrc" alt="">
      </div>
      <span>温馨提示</span>
      <span>（需先扫码授权后才能看到数据）</span>
    </div>
  </div>
</template>

<script>
import TrillAccountModule from '@/components/creativecentermodule/TrillAccountModule.vue'
import LiveDataSituation from '@/components/creativecentermodule/LiveDataSituation.vue'
import LiveSingleData from '@/components/creativecentermodule/LiveSingleData.vue'
import {mapMutations} from 'vuex'
import axios from 'axios'
export default {
  name: "LiveManagement",
  components:{
    TrillAccountModule,
    LiveDataSituation,
    LiveSingleData
  },
  data() {
    return {
      platformValue: 1, //平台标识
      //平台选择
      platformList: [
        {
          value: 1,
          label: '抖音',
          imgSrc: require('../../../assets/img/image/platform-1.png')
        },
        {
          value: 2,
          label: '快手',
          imgSrc: require('../../../assets/img/image/platform-2.png')
        },
        {
          value: 3,
          label: '哔哩哔哩',
          imgSrc: require('../../../assets/img/image/platform-3.png')
        },
        {
          value: 4,
          label: '美拍',
          imgSrc: require('../../../assets/img/image/platform-6.png')
        },
        {
          value: 5,
          label: '西瓜',
          imgSrc: require('../../../assets/img/image/platform-5.png')
        },
        {
          value: 6,
          label: '火山视频',
          imgSrc: require('../../../assets/img/image/platform-4.png')
        }
      ],
      // 平台头像
      platformCover: require('../../../assets/img/image/platform-1.png'),
      //账号选择
      accountValue: '',
      accountList: [],
      accountCover:null,
      blank_avatar: require('../../../assets/img/image/blank_headPic.png'),
      activeName:'first',
      update_time:'',
      qrCodeShow:false,
      qrCodeSrc:null,
      authorization:localStorage.getItem('studentToken'),
      qrTime:null,
    }
  },
  mounted() {
    this.getPlatformAccount();
  },
  methods: {
    ...mapMutations(['setStudentLiveData']),
    handleClick(tab){
      if(tab.name === 'second'){
        this.$nextTick(()=>{
          this.$refs.LiveSingleData.deleDateScopeValue();
          if(this.accountValue && this.platformValue){
            this.$refs.LiveSingleData.getTiktokLive(this.accountValue,this.platformValue)
          }
        })
      }
    },
    closeQrCode(){
      clearInterval(this.qrTime);
      this.qrCodeShow = false;
    },
    getPlatformAccount() {
      if(this.$refs.LiveSingleData){
        this.$refs.LiveSingleData.deleDateScopeValue();
      }
      this.$httpStudent.axiosGetBy(this.$api.get_account, {platform_type: this.platformValue, type: 1}, res => {
        if (res.code === 200) {
          this.accountList = res.data.data;
          if(this.accountList.length){
            this.accountValue = this.accountList[0].id;
            this.accountListSelection()
          } else {
            this.accountValue = '';
            this.setStudentLiveData({});
            this.$refs.LiveDataSituation.basicsDataFun();
            this.$refs.LiveDataSituation.viewDataFun();
            this.$refs.LiveDataSituation.interactionDataFun();
          }
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    platformSelection(val) {
      this.accountValue = '';
      this.accountCover = null;
      for (let i = 0; i < this.platformList.length; i++) {
        if (this.platformList[i].value === this.platformValue) {
          this.platformCover = this.platformList[i].imgSrc;
          break;
        }
      }
      this.getPlatformAccount()
    },
    accountListSelection(val) {
      if(this.$refs.LiveSingleData){
        this.$refs.LiveSingleData.deleDateScopeValue();
      }
      for (let i = 0; i < this.accountList.length; i++) {
        if (this.accountList[i].id === this.accountValue) {
          this.accountCover = this.accountList[i].avatar;
          break;
        }
      }
      let type = 1;
      // 获取直播数据总览
      let params = {
        id:this.accountValue,
        platform_type:this.platformValue
      }
      this.$httpStudent.axiosGetBy(this.$api.tiktok_live_change, params,res => {
        if(res.code === 200){
          this.setStudentLiveData(res.data);
          this.$refs.TrillAccountModule.getAccountMine(type,this.platformValue,this.accountValue,)
          this.$refs.LiveDataSituation.basicsDataFun();
          this.$refs.LiveDataSituation.viewDataFun();
          this.$refs.LiveDataSituation.interactionDataFun();
          if(this.$refs.LiveSingleData){
            this.$refs.LiveSingleData.getTiktokLive(this.accountValue,this.platformValue)
          }
        } else if(res.code === 403){
          if(this.platformValue === 1){
            // 未绑定授权的抖音账号
            this.$httpStudent.axiosGetBy(this.$api.getVideosCode, {account_id:this.accountValue}, qrRes => {
              if(qrRes.code === 200){
                this.qrCodeSrc = qrRes.data.src;
                this.qrCodeShow = true;
                let _this = this;
                this.qrTime = setInterval(function (){
                  axios({
                    methods: 'get',
                    headers:{
                      Authorization:_this.authorization
                    },
                    url:_this.$api.tiktok_checkQrCode + '?id=' + qrRes.data.id
                  }).then(checkRes=>{
                    if(checkRes.data.code === 200){
                      _this.$message({
                        type: "success",
                        message: checkRes.data.message,
                        duration: 500,
                        onClose:()=>{
                          clearInterval(_this.qrTime);
                          _this.qrCodeShow = false;
                          _this.accountListSelection();
                        }
                      });
                    }
                  }).catch(checkErr=>{
                    _this.$message.warning(checkErr)
                  })
                },1000)
              } else {
                this.$message.warning(qrRes.message)
              }
            })
          }
        } else {
          this.setStudentLiveData({})
          this.$message.warning(res.message)
          this.$refs.LiveDataSituation.basicsDataFun();
          this.$refs.LiveDataSituation.viewDataFun();
          this.$refs.LiveDataSituation.interactionDataFun();
          if(this.$refs.LiveSingleData){
            this.$refs.LiveSingleData.getTiktokLive(this.accountValue,this.platformValue)
          }
        }
      })
    },
    // 更新抖音账户数据
    updateBtn(){
      this.accountListSelection()
    }
  }
}
</script>

<style scoped lang="scss">
.live-container {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  .live-top {
    display: flex;
    align-items: center;
    .live-top-left,.live-top-tight{
      position: relative;
      .platform-cover{
        position: absolute;
        width: 26px;
        height: 26px;
        left: 10px;
        top: calc(50% - 13px);
        border-radius: 50%;
      }
    }
    .platform-select, .account-select {
      width: 210px;

      ::v-deep .el-input__inner {
        height: 40px;
        line-height: 40px;
      }
      &.platform-select
      ::v-deep .el-input__icon {
        line-height: 40px;
      }

      &.platform-select {
        margin-right: 20px;
      }

      ::v-deep.el-select-dropdown {
        .el-select-dropdown__list {
          .el-select-dropdown__item {
            display: flex;
            align-items: center;
            padding: 0 10px;
            img {
              margin-right: 10px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .live-bottom{
    display: flex;
    position: relative;
    margin-top: 18px;
    flex: 1;
    height: 1%;
    ::v-deep.el-tabs{
      box-shadow: none;
      border: none;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      background: transparent;
      flex: 1;
      width: 1%;

      position: relative;
    }
    ::v-deep.el-tabs__header{
      border-bottom: none;
      margin: 0;
    }
    ::v-deep.el-tabs__content{
      flex: 1;
      background: #fff;
      padding-top: 20px;
      .el-tab-pane:nth-child(1){
        min-height: 810px;
      }
    }
    .el-tab-pane{
      height: 100%;
    }
    ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
      background: #fff;
    }
    ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item{
      border-left: none;
    }
    ::v-deep.el-tabs__header .is-active {
      background: #fff;
      font-size: 16px;
      font-weight: 400;
      color: #0824ce;
      border-radius: 6px 6px 0 0;
    }
    ::v-deep.el-tabs__item{
      height: 45px;
      line-height: 45px;
      padding: 0 40px!important;
    }
    ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
      border: none;
    }
    ::v-deep.el-tabs__item:hover {
      color: #0824ce;
      cursor: pointer;
    }
    .update-content{
      height: 20px;
      position: absolute;
      top: 20px;
      right: 378px;
      .update-title{
        margin: 0 10px;
        font-size: 14px;
        cursor: pointer;
        i{
          color: #0824ce;
          margin-right: 10px;
        }
        &:hover{
          color: #1D33E1;
        }
      }
    }
  }
}
.platform-select-img{
  ::v-deep .el-input__inner {
    padding: 0 15px 0 42px;
  }
}
.qr-code-box{
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.4);
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  i{
    font-size: 24px;
    color: #fff;
    position: absolute;
    right: 4%;
    top: 4%;
    cursor: pointer;
  }
  .qr-code{
    width: 343px;
    height: 341px;

    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  span{
    color: #fff;
    &:first-of-type{
      font-size: 36px;
      margin: 30px 0;
    }
    &:last-of-type{
      font-size: 16px;
    }
  }
}
</style>