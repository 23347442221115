<template>
  <div class="data-situation">
    <div class="data-situation-header">
      <div class="situation-top-item">
        <span>直播总场次</span>
        <span>{{getStudentLiveData.all_frequency}}</span>
      </div>
      <div class="situation-top-item">
        <span>直播总时长</span>
        <span>{{getStudentLiveData.all_duration}}</span>
      </div>
      <div class="situation-top-item">
        <span>观看总人数</span>
        <span>{{getStudentLiveData.all_number_pviewers}}</span>
      </div>
      <div class="situation-top-item">
        <span>新增粉丝数</span>
        <span>{{getStudentLiveData.all_new_fans}}</span>
      </div>
      <span class="lately-title">近30天数据</span>
    </div>
    <div class="data-situation-basics">
      <div class="situation-basics-header">
        <div class="basics-title">
          <div class="title-line"></div>
          <span>基础数据</span>
        </div>
        <div class="basics-select-time">
          <div class="basics-time-item" @click="toggleBasics(1)" :class="{'time-item-active' : basicsRecently === 1}">7天</div>
          <div class="basics-time-item" @click="toggleBasics(2)" :class="{'time-item-active' : basicsRecently === 2}">30天</div>
        </div>
      </div>
      <div class="situation-basics-select">
        <el-select style="width: 170px;" v-model="basicsValue" placeholder="请选择" @change="selectBasicsChange">
          <el-option
              v-for="item in basicsList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div id="basicsData" style="width: 100%;height: 442px;position: relative"></div>
    </div>
    <div class="data-situation-view">
      <div class="situation-basics-header">
        <div class="basics-title">
          <div class="title-line"></div>
          <span>观看数据</span>
        </div>
        <div class="basics-select-time">
          <div class="basics-time-item" @click="toggleView(1)" :class="{'time-item-active' : viewRecently === 1}">7天</div>
          <div class="basics-time-item" @click="toggleView(2)" :class="{'time-item-active' : viewRecently === 2}">30天</div>
        </div>
      </div>
      <div class="situation-basics-select">
        <el-select style="width: 170px;" v-model="viewValue" placeholder="请选择" @change="selectViewChange">
          <el-option
              v-for="item in viewList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div id="viewData" style="width: 100%;height: 442px;position: relative"></div>
    </div>
    <div class="data-situation-interaction">
      <div class="situation-basics-header">
        <div class="basics-title">
          <div class="title-line"></div>
          <span>互动数据</span>
        </div>
        <div class="basics-select-time">
          <div class="basics-time-item" @click="toggleInteraction(1)" :class="{'time-item-active' : interactionRecently === 1}">7天</div>
          <div class="basics-time-item" @click="toggleInteraction(2)" :class="{'time-item-active' : interactionRecently === 2}">30天</div>
        </div>
      </div>
      <div class="situation-basics-select">
        <el-select style="width: 170px;" v-model="interactionValue" placeholder="请选择" @change="selectInteractionChange">
          <el-option
              v-for="item in interactionList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div id="interactionData" style="width: 100%;height: 442px;position: relative"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "LiveDataSituation",
  data() {
    return {
      basicsRecently: 1,
      //基础数据没有数据提示
      basicsDataNoPrompt: null,
      //筛选基础数据
      basicsValue:1,
      basicsList:[
        {
          value:1,
          label:'直播场次'
        },
        {
          value:2,
          label:'直播时长（分钟）'
        }
      ],
      // 观看数据
      viewRecently:1,
      //观看数据没有数据提示
      viewDataNoPrompt: null,
      viewValue:1,
      viewList:[
        {
          value:1,
          label:'观看人次'
        },
        {
          value:2,
          label:'观看人数'
        },
        {
          value:3,
          label:'观看人数峰值'
        },
        {
          value:4,
          label:'人均观看时长（分钟）'
        },
      ],
      // 互动数据
      interactionRecently:1,
      interactionValue:1,
      interactionList:[
        {
          value:1,
          label:'评论数'
        },
        {
          value:2,
          label:'评论人数'
        },
        {
          value:3,
          label:'新增粉丝'
        },
      ],
      //观看数据没有数据提示
      interactionDataNoPrompt: null,
    }
  },
  computed:{
    ...mapGetters(['getStudentLiveData'])
  },
  mounted() {
    this.basicsDataNoPrompt = document.createElement("div");
    this.viewDataNoPrompt = document.createElement("div");
    this.interactionDataNoPrompt = document.createElement("div");
    window.onresize = () => {
      // 基础数据
      this.$echarts.init(document.getElementById('basicsData')).resize()
      // 观看数据
      this.$echarts.init(document.getElementById('viewData')).resize()
      // 互动数据
      this.$echarts.init(document.getElementById('interactionData')).resize()
    }
  },
  methods: {
    // 选择直播场次 直播时长
    selectBasicsChange(){
      this.basicsDataFun()
    },
    // 选择观看数据
    selectViewChange(){
      this.viewDataFun()
    },
    // 选择互动数据
    selectInteractionChange(){
      this.interactionDataFun();
    },
    //基础数据
    basicsDataFun() {
      let arr = [];
      //7天和30天
      if(Object.keys(this.getStudentLiveData).length > 0){
        if(this.basicsRecently === 1){
          arr = this.basicsValue === 1 ?  this.getStudentLiveData.live_frequency.slice(-7) : this.getStudentLiveData.live_duration.slice(-7);
        } else {
          arr = this.basicsValue === 1 ? this.getStudentLiveData.live_frequency : this.getStudentLiveData.live_duration;
        }
      }
      let basicsData = this.$echarts.init(document.getElementById('basicsData'));
      let time = [],
          session = [];
      arr.forEach(item=>{
        time.push(item.date);
        session.push(item.count)
      })
      let max = Math.max(...session);
      let basicsTitle = this.basicsValue === 1 ? '直播场次' : '直播时长（分钟）';
      basicsData.setOption({
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#fff',
          extraCssText: 'box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);',
          padding:[8,20],
          formatter:function(params){
            return `<span style="color: #999999;font-size: 12px">${params[0].axisValue}</span><br><span style="font-size: 12px;color: #333">${basicsTitle}：</span><span style="font-size: 12px;color: #5F55F1;font-weight: bold">${params[0].value}</span>`
          },
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        color: '#584EEE',
        legend: {},
        //false用于多个grid对齐，true用于防止标签溢出
        grid:{
          containLabel:true,
          x:44,
        },
        xAxis: {
          type: "category",
          //从0开始
          boundaryGap:false,
          data: time,
          // 隐藏刻度线
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#999',
              fontSize: 14
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#999"
            }
          }
        },
        yAxis: {
          type: "value",
          name: '场',
          // min: min,
          max: max,
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#E6E6E6"]
            }
          },
          //隐藏刻度线
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#999",
              fontSize: 14
            }
          },
          axisLabel: {
            fontSize: "14",
            marginTop: "35px",
            formatter: function (value, index) {
              if (value >= 10000) {
                return `${value / 10000}W`
              } else {
                return value
              }
            },
            show: true
          }
        },
        series: [{
          data: session,
          type: "line",
          lineStyle: {
            width: 3,
            shadowColor: "rgba(95,85,241,0.5)",
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowBlur: 26,
          },
          smooth: true,
          // symbol: "none",
          showSymbol: false, //关闭线上默认的圆点
          symbolSize: 5, //设置圆点的大小
          itemStyle: {
            emphasis: { // 鼠标经过时：
              color: '#584EEE',
              borderColor: '#ffffff',
              borderWidth: 4,
              shadowColor: 'rgba(133,127,221,1)',
            },
            normal: {
              shadowBlur: 30,
            }
          },
        }],
      }, true);
      if (session.length === 0) {
        this.basicsDataNoPrompt.innerHTML = "暂无数据";
        this.basicsDataNoPrompt.style.color = "#666";
        this.basicsDataNoPrompt.style.position = "absolute";
        this.basicsDataNoPrompt.style.top = "100px";
        this.basicsDataNoPrompt.style.left = "50%";
        this.basicsDataNoPrompt.className = 'basicsDataNoPrompt';
        document.querySelector("#basicsData").appendChild(this.basicsDataNoPrompt)
      } else {

        if(document.getElementsByClassName('basicsDataNoPrompt').length){
          let basicsDataNoPrompt = document.getElementsByClassName('basicsDataNoPrompt');
          document.querySelector("#basicsData").removeChild(basicsDataNoPrompt[0])
        }
      }
    },
    //观看数据
    viewDataFun(){
      let arr = [];
      if(Object.keys(this.getStudentLiveData).length > 0){
        if(this.viewRecently === 1){
          arr =
              this.viewValue === 1 ? this.getStudentLiveData.live_number_viewers.slice(-7) :
              this.viewValue === 2 ? this.getStudentLiveData.live_number_pviewers.slice(-7) :
              this.viewValue === 3 ? this.getStudentLiveData.live_peak_number_viewers.slice(-7) : this.getStudentLiveData.live_avg_viewing_time.slice(-7);
        } else {
          arr =
              this.viewValue === 1 ? this.getStudentLiveData.live_number_viewers :
              this.viewValue === 2 ? this.getStudentLiveData.live_number_pviewers :
              this.viewValue === 3 ? this.getStudentLiveData.live_peak_number_viewers : this.getStudentLiveData.live_avg_viewing_time;
        }
      }
      let viewData = this.$echarts.init(document.getElementById('viewData'));
      let time = [],
          session = [];
      arr.forEach(item=>{
        time.push(item.date);
        session.push(item.count)
      })
      let max = Math.max(...session);
      let viewTitle = this.viewValue === 1 ? '观看人次' :
      this.viewValue === 2 ? '观看人数' : this.viewValue === 3 ? '观看人数峰值' : '人均观看时长（分钟）';
      ;
      viewData.setOption({
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#fff',
          extraCssText: 'box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);',
          padding:[8,20],
          formatter:function(params){
            return `<span style="color: #999999;font-size: 12px">${params[0].axisValue}</span><br><span style="font-size: 12px;color: #333">${viewTitle}：</span><span style="font-size: 12px;color: #5F55F1;font-weight: bold">${params[0].value}</span>`
          },
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        color: '#584EEE',
        legend: {},
        //false用于多个grid对齐，true用于防止标签溢出
        grid:{
          containLabel:true,
          x:44,
        },
        xAxis: {
          type: "category",
          //从0开始
          boundaryGap:false,
          data: time,
          // 隐藏刻度线
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#999',
              fontSize: 14
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#999"
            }
          }
        },
        yAxis: {
          type: "value",
          name: '场',
          // min: min,
          max: max,
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#E6E6E6"]
            }
          },
          //隐藏刻度线
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#999",
              fontSize: 14
            }
          },
          axisLabel: {
            fontSize: "14",
            marginTop: "35px",
            formatter: function (value, index) {
              if (value >= 10000) {
                return `${value / 10000}W`
              } else {
                return value
              }
            },
            show: true
          }
        },
        series: [{
          data: session,
          type: "line",
          lineStyle: {
            width: 3,
            shadowColor: "rgba(95,85,241,0.5)",
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowBlur: 26,
          },
          smooth: true,
          // symbol: "none",
          showSymbol: false, //关闭线上默认的圆点
          symbolSize: 5, //设置圆点的大小
          itemStyle: {
            emphasis: { // 鼠标经过时：
              color: '#584EEE',
              borderColor: '#ffffff',
              borderWidth: 4,
              shadowColor: 'rgba(133,127,221,1)',
            },
            normal: {
              shadowBlur: 30,
            }
          },
        }],
      },true);
      if (session.length === 0) {
        this.viewDataNoPrompt.innerHTML = "暂无数据";
        this.viewDataNoPrompt.style.color = "#666";
        this.viewDataNoPrompt.style.position = "absolute";
        this.viewDataNoPrompt.style.top = "100px";
        this.viewDataNoPrompt.style.left = "50%";
        this.viewDataNoPrompt.className = 'viewDataNoPrompt'
        document.querySelector("#viewData").appendChild(this.viewDataNoPrompt)
      } else {
        if(document.getElementsByClassName('viewDataNoPrompt').length){
          let viewDataNoPrompt = document.getElementsByClassName('viewDataNoPrompt');
          document.querySelector("#viewData").removeChild(viewDataNoPrompt[0])
        }
      }
    },
    //互动数据
    interactionDataFun(){
      let arr = [];
      if(Object.keys(this.getStudentLiveData).length > 0){
        if(this.interactionRecently === 1){
          arr = this.interactionValue === 1 ? this.getStudentLiveData.live_comments.slice(-7) :
              this.interactionValue === 2 ? this.getStudentLiveData.live_pcomments.slice(-7) : this.getStudentLiveData.live_new_fans.slice(-7);
        } else {
          arr = this.interactionValue === 1 ? this.getStudentLiveData.live_comments :
              this.interactionValue === 2 ? this.getStudentLiveData.live_pcomments : this.getStudentLiveData.live_new_fans;
        }
      }
      let interactionData = this.$echarts.init(document.getElementById('interactionData'));
      let time = [],
          session = [];
      arr.forEach(item=>{
        time.push(item.date);
        session.push(item.count)
      })
      let max = Math.max(...session);
      let interactionTitle = this.interactionValue === 1 ? '评论数' : this.interactionValue === 2 ? '评论人数' : '新增粉丝';
      interactionData.setOption({
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#fff',
          extraCssText: 'box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);',
          padding:[8,20],
          formatter:function(params){
            return `<span style="color: #999999;font-size: 12px">${params[0].axisValue}</span><br><span style="font-size: 12px;color: #333">${interactionTitle}：</span><span style="font-size: 12px;color: #5F55F1;font-weight: bold">${params[0].value}</span>`
          },
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        color: '#584EEE',
        legend: {},
        //false用于多个grid对齐，true用于防止标签溢出
        grid:{
          containLabel:true,
          x:44,
        },
        xAxis: {
          type: "category",
          //从0开始
          boundaryGap:false,
          data: time,
          // 隐藏刻度线
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#999',
              fontSize: 14
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#999"
            }
          }
        },
        yAxis: {
          type: "value",
          name: '场',
          // min: min,
          max: max,
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#E6E6E6"]
            }
          },
          //隐藏刻度线
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#999",
              fontSize: 14
            }
          },
          axisLabel: {
            fontSize: "14",
            marginTop: "35px",
            formatter: function (value, index) {
              if (value >= 10000) {
                return `${value / 10000}W`
              } else {
                return value
              }
            },
            show: true
          }
        },
        series: [{
          data: session,
          type: "line",
          lineStyle: {
            width: 3,
            shadowColor: "rgba(95,85,241,0.5)",
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowBlur: 26,
          },
          smooth: true,
          // symbol: "none",
          showSymbol: false, //关闭线上默认的圆点
          symbolSize: 5, //设置圆点的大小
          itemStyle: {
            emphasis: { // 鼠标经过时：
              color: '#584EEE',
              borderColor: '#ffffff',
              borderWidth: 4,
              shadowColor: 'rgba(133,127,221,1)',
            },
            normal: {
              shadowBlur: 30,
            }
          },
        }],
      }, true);
      if (session.length === 0) {
        this.interactionDataNoPrompt.innerHTML = "暂无数据";
        this.interactionDataNoPrompt.style.color = "#666";
        this.interactionDataNoPrompt.style.position = "absolute";
        this.interactionDataNoPrompt.style.top = "100px";
        this.interactionDataNoPrompt.style.left = "50%";
        this.interactionDataNoPrompt.className = 'interactionDataNoPrompt';
        document.querySelector("#interactionData").appendChild(this.interactionDataNoPrompt)
      } else {
        if(document.getElementsByClassName('interactionDataNoPrompt').length){
          let interactionDataNoPrompt = document.getElementsByClassName('interactionDataNoPrompt');
          document.querySelector("#interactionData").removeChild(interactionDataNoPrompt[0])
        }
      }
    },
    toggleBasics(num) {
      this.basicsRecently = num;
      this.basicsDataFun()
    },
    toggleView(num){
      this.viewRecently = num;
      this.viewDataFun();
    },
    toggleInteraction(num){
      this.interactionRecently = num;
      this.interactionDataFun();
    }
  }
}
</script>

<style scoped lang="scss">
.data-situation {
  height: 100%;
  display: flex;
  flex-direction: column;

  .data-situation-header {
    display: flex;
    justify-content: space-between;
    padding: 34px 132px 82px 52px;
    position: relative;
    .lately-title{
      position: absolute;
      left: 20px;
      top: -10px;
      font-size: 14px;
      font-weight: bold;
    }
    .situation-top-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        line-height: 1;

        &:nth-child(1) {
          font-size: 14px;
          color: #666666;
          font-weight: 400;
        }

        &:nth-child(2) {
          color: #584EEE;
          font-size: 24px;
          font-weight: bold;
          margin-top: 20px;
        }
      }
    }
  }

  .data-situation-basics ,.data-situation-view, .data-situation-interaction{
    padding: 0 50px 0 20px;
    &.data-situation-interaction{
      padding-bottom: 40px;
    }
    .situation-basics-header {
      display: flex;
      line-height: 1;
      justify-content: space-between;

      .basics-title {
        display: flex;
        align-items: center;

        .title-line {
          width: 5px;
          height: 20px;
          background: #584EEE;
          border-radius: 3px;
          margin-right: 10px;
        }

        span {
          font-size: 16px;
          color: #333;
        }
      }

      .basics-select-time {
        display: flex;

        .basics-time-item {
          background: #F1F0FE;
          font-size: 12px;
          color: #666666;
          line-height: 30px;
          text-align: center;
          width: 50px;
          height: 30px;
          cursor: pointer;

          &:nth-child(1) {
            border-radius: 5px 0px 0px 5px;
          }

          &:nth-child(2) {
            border-radius: 0px 5px 5px 0px;
          }
        }

        .time-item-active {
          background: #564CED;
          color: #fff;
        }
      }
    }
    .situation-basics-select{
      margin-top: 22px;
      padding-left: 14px;
    }
  }
}
</style>